// _pluginsフォルダからfocusable.jsをインポート
import focusable from './_plugins/focusable'
// focusable関数を呼び出す
focusable()

// jQueryをインポート
import $ from 'jquery'

// 各モジュールをインポート
import component from './_import/_component'
import matchMedia from './_import/_match-media'
import narrowViewport from './_import/_narrow-viewport'
import setVh from './_import/_set-vh'
import tab from './_import/_tab'
import accordion from './_import/_accordion'
import modal from './_import/_modal'
import globalNav from './_import/_global-nav'
import isScrollbar from './_import/_isScrollbar'
import scrollAnimation from './_import/_scroll-animation'

// componentオブジェクトに'matchMedia'という名前でmatchMediaモジュールを追加
component.add('matchMedia', matchMedia)

// 各モジュールの初期化関数を実行
narrowViewport.init()
setVh.init()
globalNav.init()
tab.init()
accordion.init()
modal.init()
isScrollbar.init()
scrollAnimation.init()

// componentオブジェクトに各モジュールを追加
component.add('tab', tab)
component.add('modal', modal)
component.add('accordion', accordion)

// 初期化関数を定義し、'no-js'クラスを削除する
const init = () => {
  $('html').removeClass('no-js')
}

// 初期化関数を実行
init()
