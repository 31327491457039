// Accordionコンポーネント用のJSファイル

import $ from 'jquery'
import component from './_component'

// レスポンシブ対応のブレークポイントと、対応する名前を定義
const mqList = [
  {
    query: '--to-md',
    name: 'sp',
  },
  {
    query: '--md-lg',
    name: 'tb',
  },
  {
    query: '--lg',
    name: 'pc',
  },
]

// アコーディオンの初期化処理
function init() {
  // アコーディオンのボタンがクリックされたときの処理
  $(document).on('click', '.js-accordion_btn', function (e) {
    e.preventDefault()

    // 必要なDOM要素を取得
    const $btn = $(this)
    const $wrap = $(this).closest('.js-accordion')
    const isClass = $wrap.attr('data-isclass') === 'true'
    const $target = $('#' + $btn.attr('aria-controls'))
    const animationType = $wrap.attr('data-animation-type')

    // スライドアニメーションの場合
    if ($target[0]) {
      if (animationType === 'slide') {
        if (
          $target.attr('aria-hidden') === 'false' ||
          $target.hasClass('is-open')
        ) {
          // 閉じる処理
          $btn.addClass('is-close')
          $btn.removeClass('is-open')
          $btn.attr('aria-expanded', false)

          $target.slideUp(300, function () {
            if (isClass) {
              $target.addClass('is-close')
              $target.removeClass('is-open')
            } else {
              $target.attr({ 'aria-hidden': true })
            }
            $target.css({ display: '' })
          })
        } else {
          // 開く処理
          $btn.removeClass('is-close')
          $btn.addClass('is-open')
          $btn.attr('aria-expanded', true)
          if (isClass) {
            $target.addClass('is-close')
            $target.removeClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': true })
          }

          $target.slideDown(300, function () {
            if (isClass) {
              $target.addClass('is-open')
              $target.removeClass('is-close')
            } else {
              $target.attr({ 'aria-hidden': false })
            }
            $target.css({ display: '' })
          })
        }
      } else {
        // スライドアニメーション以外の場合
        if (
          $target.attr('aria-hidden') === 'false' ||
          $target.hasClass('is-open')
        ) {
          // 閉じる処理
          $btn.attr('aria-expanded', false)
          if (isClass) {
            $target.addClass('is-close')
            $target.removeClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': true })
          }
          $btn.addClass('is-close')
          $btn.removeClass('is-open')
        } else {
          // 開く処理
          $btn.attr('aria-expanded', true)
          if (isClass) {
            $target.addClass('is-open')
            $target.removeClass('is-close')
          } else {
            $target.attr({ 'aria-hidden': false })
          }
          $btn.removeClass('is-close')
          $btn.addClass('is-open')
        }
      }
    }
  })

  // アコーディオンの閉じるボタンがクリックされたときの処理
  $(document).on('click', '.js-accordion_close', function (e) {
    e.preventDefault()
  })
}

// アコーディオンの状態をリセットする処理
function accordionReset() {
  // 各アコーディオンボタンに対して以下の処理を実行
  $('.js-accordion_btn').each(function () {
    const $btn = $(this)
    const $wrap = $btn.closest('.js-accordion')
    const isClass = $wrap.attr('data-isclass') === 'true'
    const responsive = $wrap.attr('data-accordion-responsive')
    const aria = eval('(' + responsive + ')')
    let targetId = $btn.attr('data-target')
    let $target = (function () {
      if (targetId) {
        return $('#' + targetId)
      } else {
        targetId = $wrap.find('.js-accordion_contents').attr('id')
        return $wrap.find('.js-accordion_contents')
      }
    })()

    // アコーディオンの状態を各ブレークポイントに応じて設定する
    const set = function (query, name) {
      if (component.matchMedia[query].matches) {
        const _open = function () {
          if (isClass) {
            $target.addClass('is-open')
            $target.removeClass('is-close')
          } else {
            $target.attr({ 'aria-hidden': false })
          }
          $btn.attr({ 'aria-expanded': true })
        }
        const _close = function () {
          if (isClass) {
            $target.addClass('is-close')
            $target.removeClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': true })
          }
          $btn.attr({ 'aria-expanded': false })
        }
        if ($btn.hasClass('is-open')) {
          _open()
        } else if ($btn.hasClass('is-close') && aria[name] !== null) {
          _close()
        } else if (aria[name] === null || aria[name] === true) {
          _open()
        } else if (aria[name] === false) {
          _close()
        }

        if (aria[name] === null) {
          $wrap.addClass('is-accordion-null')
          $btn.attr('disabled', true)
        } else {
          $wrap.removeClass('is-accordion-null')
          $btn.removeAttr('disabled')
        }
      }
    }

    // 各ブレークポイントに対してアコーディオンの状態を設定する
    if (typeof aria !== 'undefined') {
      for (let index = 0; index < mqList.length; index++) {
        set(mqList[index].query, mqList[index].name)
      }
    }
  })
}

// アコーディオンの初期化処理
function accordionInit() {
  // 各アコーディオンボタンに対して以下の処理を実行
  $('.js-accordion_btn').each(function (i) {
    const $btn = $(this)
    const $wrap = $btn.closest('.js-accordion')
    // 既に初期化済みの場合は処理を中断する
    if ($wrap.hasClass('is-initialized')) return

    const isClass = $wrap.attr('data-isclass') === 'true'
    const responsive = $wrap.attr('data-accordion-responsive')
    const aria = eval('(' + responsive + ')')
    let targetId = $btn.attr('data-target')
    const $target = (function () {
      if (targetId) {
        return $('#' + targetId)
      } else {
        if ($wrap.find('.js-accordion_contents').eq(0).attr('id')) {
          targetId = $wrap.find('.js-accordion_contents').eq(0).attr('id')
        } else {
          targetId = 'a11y-accordion-contents' + i
          $wrap.find('.js-accordion_contents').eq(0).attr('id', targetId)
        }
        return $wrap.find('.js-accordion_contents').eq(0)
      }
    })()

    // アコーディオンの状態を各ブレークポイントに応じて設定する
    const set = function (query, name) {
      if (component.matchMedia[query].matches) {
        if (aria[name] === null) {
          if (isClass) {
            $target.addClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': false })
          }
          $btn.attr({ 'aria-expanded': true })
          $wrap.addClass('is-accordion-null')
          $btn.attr('disabled', true)
        } else {
          if (isClass) {
            $target.addClass(aria[name] ? 'is-open' : 'is-close')
          } else {
            $target.attr({ 'aria-hidden': !aria[name] })
          }
          $btn.attr({ 'aria-expanded': aria[name] })
          $wrap.removeClass('is-accordion-null')
          $btn.removeAttr('disabled')
        }
      }
    }

    if ($target[0]) {
      const btnId = $btn.attr('id') ? $btn.attr('id') : 'a11y-accordion-btn' + i

      // アコーディオンの開閉に関連する属性を設定する
      $btn.attr('aria-controls', targetId)
      $btn.attr('id', btnId)

      // 各ブレークポイントに対してアコーディオンの状態を設定する
      if (typeof aria !== 'undefined') {
        for (let index = 0; index < mqList.length; index++) {
          set(mqList[index].query, mqList[index].name)
        }
      } else {
        // data-accordion-responsive 属性が設定されていない場合はデフォルトの状態を設定する
        if (
          $wrap.hasClass('is-open') ||
          $btn.hasClass('is-open') ||
          $btn.attr('aria-expanded') === 'true' ||
          $target.attr('aria-hidden') === 'false'
        ) {
          $btn.attr('aria-expanded', true)
          $target.attr('aria-hidden', false)
        } else {
          $btn.attr('aria-expanded', false)
          $target.attr('aria-hidden', true)
        }
      }
      // 初期化済みのフラグを立てる
      $wrap.addClass('is-initialized')
    }
  })
}
// ページの読み込みが完了したら以下の処理を実行する
function ready() {
  // 各ブレークポイントに対してリセット処理を実行する
  component.matchMedia['--to-md'].addListener(function (mql) {
    if (mql.matches) accordionReset()
  })
  component.matchMedia['--md-lg'].addListener(function (mql) {
    if (mql.matches) accordionReset()
  })
  component.matchMedia['--lg'].addListener(function (mql) {
    if (mql.matches) accordionReset()
  })

  // アコーディオンを初期化する
  accordionInit()

  // グローバルスコープにアコーディオンを初期化する関数を設定する
  window.accordionInit = accordionInit
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
  accordionInit: accordionInit,
}
